import React, { FC, PropsWithChildren } from "react";

import { Logo } from "../../pages/shared-components/logo";
import { ProgressBar } from "../../pages/shared-components/progress-bar";

import LogoPatternImage from "../../assets/images/logo-pattern.png";

const styleBackground = {
  backgroundImage: `url(${LogoPatternImage})`,
  backgroundRepeat: "repeat",
};

export const AuthLayout: FC<
  PropsWithChildren<{ title: string; isLoading?: boolean }>
> = ({ title, isLoading = false, children }) => {
  return (
    <div
      className="font-body tracking-wide text-gray-700 bg-dark min-h-screen"
      style={styleBackground}
    >
      <div className="relative block py-10 md:py-20">
        <div className="container max-w-md mx-auto px-4">
          <div className="w-full bg-white rounded shadow relative overflow-hidden mb-6">
            <ProgressBar isLoading={isLoading} />
            <div className="flex-auto p-6 lg:p-8">
              <div className="flex justify-center">
                <Logo />
              </div>
              <hr className="my-6" />
              <h4 className="font-display text-xl text-center mb-6">{title}</h4>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
