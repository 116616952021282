import { PropsWithChildren, FC, useEffect, useState } from "react";
import Select, { OptionProps, components } from "react-select";
import { numbersOnly } from "../form-validation/input-number-validation";
import { Controller } from "react-hook-form";
import "./styles.css";
import { ReactComponent as Star } from "../../../icons/star.svg";
import { Tooltip } from "react-tooltip";

type InputWithSelectType = {
  title: string;
  input_name: string;
  input_id: string;
  select_name: string;
  options: Array<{
    name: any;
    value: boolean | string;
    label: string;
  }>;
  showVertical: boolean;
  register: any;
  control: any;
  watch: any;
  defaultValue1: any;
  defaultValue2: any;
  setValue: any;
};

export const InputWithSelect: FC<PropsWithChildren<InputWithSelectType>> = ({
  title,
  input_name,
  input_id,
  select_name,
  showVertical,
  options,
  register,
  control,
  defaultValue1,
  defaultValue2,
  setValue,
  watch = false,
}) => {
  const [inputfocused, setInputFocused] = useState(false);
  useEffect(() => {
    setValue(input_name, defaultValue1);
    setValue(select_name, defaultValue2);
  }, [input_name, select_name, defaultValue1, defaultValue2, setValue]);

  const checkDeposit = () => {
    return parseInt(watch("cost")) <= parseInt(watch("deposit"));
  };

  const CustomOption: FC<OptionProps<any, boolean>> = (props) => (
    <div style={{ position: "relative", paddingBottom: "2px" }}>
      <components.Option {...props} />
      <div
        style={{
          height: "2px",
          background: "#e5e5e5",
          width: "80%",
          position: "absolute",
          bottom: -3,
          marginLeft: "10%",
        }}
      />
    </div>
  );

  return (
    <div className={`flex ${showVertical ? "flex-col" : ""}`}>
      <label
        htmlFor={input_id}
        className={`flex items-center pr-4 text-base tracking-wide ${
          showVertical ? "pb-1 text-[#b2abd1]" : ""
        }`}
      >
        {title} {title === "Asset Cost" && <Star className="mx-2" />}
      </label>
      {checkDeposit() && (
        <Tooltip
          isOpen={true}
          defaultIsOpen={true}
          place="top"
          style={{
            backgroundColor: "#f74343",
            textAlign: "center",
            zIndex: "1000",
            marginTop: "-18px",
          }}
          id="input-with-select"
        >
          The entered value is <br />{" "}
          <span style={{ fontWeight: "bold" }}>too big</span>.
        </Tooltip>
      )}
      <div
        className={`flex flex-row ${
          showVertical ? "w-full" : "w-[100%]"
        } justify-end shadow-none outline-none h-12 rounded-md ${
          inputfocused ? "border-2 border-[#fbca5d]" : ""
        } ${
          checkDeposit() && title === "Deposit" && "border-[#f74343] border-2"
        }`}
        data-tooltip-id={`${title === "Deposit" && "input-with-select"}`}
      >
        <div className="w-3/5 relative border-none shadow-none outline-none">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 rounded-md bg-transparent border-none shadow-none outline-none">
            <span
              className={`${
                inputfocused ? "text-black" : "text-[#dcd9ea]"
              } text-base opacity-80 tracking-wide shadow-none outline-none mr-2 border-none`}
            >
              {watch ? (watch(select_name) === "percentage" ? "% " : "$") : "$"}
            </span>
          </div>
          <input
            type="text"
            name={input_name}
            id={input_id}
            className={`w-full h-full pl-8 rounded-l placeholder-[white] placeholder:font-normal placeholder:opacity-70 text-base border-none text-sm font-bold shadow-none border-0 outline-transparent tracking-wide ${
              inputfocused ? "bg-white text-black" : "bg-[#514298] text-white"
            } ${checkDeposit() && title === "Deposit" && "text-[#f74343]"}`}
            placeholder="Enter"
            onKeyDown={numbersOnly}
            {...register(input_name)}
            autoComplete="off"
            onFocus={() => {
              setInputFocused(true);
            }}
            onBlur={() => {
              setInputFocused(false);
            }}
            value={defaultValue1}
          />
        </div>
        <div
          className={`w-2/5 flex items-center justify-items-end border-none shadow-none rounded-r outline-none ${
            inputfocused ? "bg-white" : "bg-[#514298]"
          }`}
        >
          <Controller
            name={select_name}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Select
                options={options.map((option) => ({
                  value: option.value,
                  label: option.name,
                  name: option.name,
                }))}
                value={
                  (typeof watch(select_name) === "boolean" ||
                    watch(select_name) === "percentage") &&
                  options.find((c) => c.value === value)
                }
                onChange={(val: any) => {
                  onChange(val.value);
                }}
                onFocus={() => setInputFocused(true)}
                onBlur={() => setInputFocused(false)}
                className="w-full text-[12px] outline-none"
                placeholder="GST"
                styles={{
                  control: (baseStyles, { isFocused }) => ({
                    ...baseStyles,
                    borderStyle: "none",
                    boxShadow: "none",
                    outline: "none",
                    textAlign: "right",
                    background: `${
                      isFocused ? "#fbca5d" : inputfocused ? "white" : "#514298"
                    }`,
                    color: `white`,
                    width: "100%",
                  }),
                  singleValue: (baseStyles) => ({
                    ...baseStyles,
                    color: `${inputfocused ? "black" : "#a8a0cc"}`,
                  }),
                  option: (baseStyles, { isFocused, data }) => ({
                    ...baseStyles,
                    textAlign: "right",
                    background: isFocused
                      ? "black"
                      : // @ts-ignore
                      value === data.value
                      ? "#fbca5d"
                      : "white",
                    color: isFocused ? "#fbca5d" : "",
                    marginTop: 5,
                    cursor: isFocused ? "pointer" : "default",
                    fontWeight: isFocused ? "bold" : "normal",
                    padding: 16,
                    width: "94%",
                    marginLeft: "3%",
                    borderRadius: 5,
                    fontSize: 14,
                  }),
                  indicatorSeparator: () => ({
                    display: "none",
                  }),
                  indicatorsContainer: (baseStyles) => ({
                    padding: "0",
                    color: "white",
                  }),
                  placeholder: (baseStyles) => ({
                    ...baseStyles,
                    color: `${inputfocused ? "black" : "white"}`,
                    opacity: "70%",
                  }),
                  menu: (baseStyles) => ({
                    ...baseStyles,
                    width: "250%",
                    marginLeft: "-150%",
                  }),
                }}
                components={{
                  Option: CustomOption,
                }}
                isSearchable={false}
                isClearable={false}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};
