import React from "react";

import { Illustration } from "./illustration";
import { MarketingLayout } from "./marketing-layout";

export const HomePage = () => {
  return (
    <MarketingLayout
      title="Welcome to Finput"
      subtitle="Coming Soon :)"
    >
      <section className="mx-auto max-w-xl px-4">
        <Illustration icon="stats" />
      </section>
    </MarketingLayout>
  );
};
