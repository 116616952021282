import React, {
  FC,
  useContext,
  useState,
  useEffect,
  useCallback,
  ChangeEvent,
  useMemo,
} from "react";
import { InputWithSelect } from "../form-components/input-with-select";
import { useForm } from "react-hook-form";
import { HorizontalBlueDivider } from "../form-components/divider";
import { InputSelect } from "../form-components/custom-select";
import AdvanceArrears from "../form-components/advance-arrears";
import { getCompanies } from "../../../services/company.service";
import { customToast } from "../../shared-components/custom-toast";
import { useMediaQuery } from "react-responsive";
import CustomDropdown from "../calculator/results-components/Custom-dropdown";
import { RepaymentResultsContext } from "../calculator/calculator.page";
import { repaymentsCalculator } from "../../../services/repayments.service";
import { ReactComponent as SearchIcon } from "../../../icons/search_button.svg";
import { ReactComponent as CancelButton } from "../../../icons/cancel_button.svg";
import { ReactComponent as SubmitButton } from "../../../icons/submit_icon.svg";
import { ReactComponent as MinimizedIcon } from "../../../icons/minimized_icon.svg";
import AbnLoader from "../../../icons/abn_loader.png";
import { ReactComponent as AbnTick } from "../../../icons/tick_abn.svg";
import { useDispatch, useSelector } from "react-redux";
import { updateLoading } from "../../../redux/slices/directorySlice";
import CustomMultiselectWithChips from "./CustomChipsInput/CustomMultiselectWithChips";
import SubmitLoader from "./submitLoader";
import { directoryState as ds } from "../../../redux/slices/directorySlice";
import { ReactComponent as DisabledSubmit } from "../../../icons/disabled_submit.svg";
import { Tooltip } from "react-tooltip";

interface Props {
  scrolled: boolean;
  setRecordsFound: any;
  clearStates: boolean;
  setClearStates: any;
  setScrolled: any;
  handleFormChange: () => void;
  setIsFormChanged: any;
  setChanges: any;
  setCancelChanges: any;
  cancelChanges: any;
  changes: any;
  recordsFound: any;
  filterRef: any;
  topPosition: any;
  scrollTop: any;
  currentContainerWidth: any;
  parentRef: any;
}

export const FilterPage: FC<Props> = ({
  scrolled,
  setRecordsFound,
  clearStates,
  setClearStates,
  setScrolled,
  handleFormChange,
  setIsFormChanged,
  setChanges,
  setCancelChanges,
  cancelChanges,
  changes,
  recordsFound,
  filterRef,
  topPosition,
  scrollTop,
  currentContainerWidth,
  parentRef,
}) => {
  const { setRepaymentResultsData } = useContext<any>(RepaymentResultsContext);
  const isMobile = useMediaQuery({ query: "(max-width: 960px)" });
  const isCustomBigScreen = useMediaQuery({ query: "(min-width: 2000px)" });

  const dispatch = useDispatch();
  const directoryState = useSelector(ds);

  const [formData, setFormData] = useState();

  const optionsRepaymentFrequency = [
    { value: "none", label: "None" },
    { value: "yearly", label: "Yearly" },
    { value: "quarterly", label: "Quarterly" },
    { value: "monthly", label: "Monthly" },
    { value: "weekly", label: "Weekly" },
    { value: "daily", label: "Daily" },
  ];

  const [options, setOptions] = useState([]);
  const optionsTermSelect = [
    { value: 1, label: "1 Year" },
    { value: 2, label: "2 Years" },
    { value: 3, label: "3 Years" },
    { value: 4, label: "4 Years" },
    { value: 5, label: "5 Years" },
    { value: 6, label: "6 Years" },
    { value: 7, label: "7 Years" },
  ];

  const loadPreferredFinancierOptions = () => {
    getCompanies()
      .then((response) => {
        setOptions(
          response.data.map((company: any) => ({
            label: company.name,
            value: company.id,
          }))
        );
      })
      .catch((err) => console.log(err));
  };

  const [doc_fee_financed, setDocFeeFinanced] = useState(null);
  const [sale_hire_back, setSaleHireBack] = useState(null);
  const [asset_owner, setAssetOwner] = useState(null);
  const [private_sale, setPrivateSale] = useState(null);
  const [abn, setAbn] = useState("");
  const [abnLoading, setAbnLoading] = useState(false);
  const [abnSuccess, setAbnSuccess] = useState(false);

  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    loadPreferredFinancierOptions();
  }, []);

  const repaymentsSuccessCallback = useCallback(
    (res: any) => {
      if (res.data.data === "No Results") {
        setRepaymentResultsData({});
        customToast({ message: "0 records found.", type: "error" });
      } else {
        setRepaymentResultsData(res.data.data);
        setRecordsFound(res.data.data.length);
      }
      dispatch(updateLoading(false));
    },
    [dispatch, setRecordsFound, setRepaymentResultsData]
  );

  const repaymentsErrorCallback = useCallback(
    (error: any) => {
      customToast({
        message: `${error.response.data.detail || "Something went wrong"}`,
        type: "error",
      });
      dispatch(updateLoading(false));
    },
    [dispatch]
  );

  const handleInputForm = (formData: any) => {
    // convert asset_year_age in months and add asset_months_age in it
    dispatch(updateLoading(true));
    handleFormChange();
    setFormData(formData);
    let asset_age = null;
    if (
      formData["asset_years_age"] !== null ||
      formData["asset_months_age"] !== null
    ) {
      asset_age =
        Number(formData["asset_years_age"]) * 12 +
        Number(formData["asset_months_age"]);
    }

    let gst_age = null;
    if (
      formData["gst_years_age"] !== null ||
      formData["gst_months_age"] !== null
    ) {
      gst_age =
        Number(formData["gst_years_age"]) * 12 +
        Number(formData["gst_months_age"]);
    }

    // convert abn_year_age in months and add abn_months_age in it
    let abn_age = null;
    if (
      formData["abn_years_age"] !== null ||
      formData["abn_months_age"] !== null
    ) {
      abn_age =
        Number(formData["abn_years_age"]) * 12 +
        Number(formData["abn_months_age"]);
    }
    let payload = {
      ...formData,
      term: Number(formData["term"]) * 12,
      deposit: Number(formData["deposit"]),
      brokerage: Number(formData["brokerage"]),
      asset_age,
      abn_age,
      gst_age,
    };

    if (!payload["cost"]) {
      customToast({
        message: "Asset cost field is required",
        type: "error",
      });
      dispatch(updateLoading(false));
      return;
    }
    if (!payload["term"]) {
      customToast({
        message: "Term field is required",
        type: "error",
      });
      dispatch(updateLoading(false));
      return;
    }

    const filteredFormData = Object.fromEntries(
      Object.entries(payload).filter(([key, value]) => value !== null)
    );

    payload = filteredFormData;

    if (!payload["repayment_frequency"]) {
      payload = { ...payload, repayment_frequency: "none" };
    }

    // Handle the value requirement for creating repayment entries
    if (payload["deposit_gst"] === "percentage") {
      payload["deposit_gst"] = null;
      payload["deposit_percentage"] = true;
    }
    if (payload["brokerage_gst"] === "percentage") {
      payload["brokerage_gst"] = null;
      payload["brokerage_percentage"] = true;
    }
    if (payload["balloon_gst"] === "percentage") {
      payload["balloon_gst"] = null;
      payload["balloon_percentage"] = true;
    }
    if (payload["preferred_financier"]) {
      const data = payload["preferred_financier"];
      payload["preferred_financier"] = data.map(
        (item: { value: number; label: string }) => item.value
      );
    }

    repaymentsCalculator(payload)
      .then(repaymentsSuccessCallback)
      .catch(repaymentsErrorCallback);
  };

  useEffect(() => {
    loadPreferredFinancierOptions();
  }, []);

  const dropDownOptions = [
    { value: "option1", label: "Not Sure" },
    { value: "option2", label: "Yes" },
    { value: "option3", label: "No" },
  ];

  const [refreshState, setRefreshState] = useState(false);

  const [reset, setReset] = useState(false);

  const toggleRefresh = useCallback(() => {
    setRefreshState(!refreshState);
  }, [setRefreshState, refreshState]);

  const defaultInputFormValues = useMemo(
    () => ({
      cost: null,
      cost_gst: null,
      deposit: null,
      deposit_gst: null,
      deposit_percentage: null,
      brokerage: null,
      brokerage_percentage: null,
      brokerage_gst: null,
      balloon: null,
      balloon_percentage: null,
      balloon_gst: null,
      term: null,
      private_sale: null,
      sale_hire_back: null,
      doc_fee_financed: null,
      asset_owner: null,
      advance_arrears: "advance",
      asset_state: null,
      asset_months_age: null,
      asset_years_age: null,
      abn_months_age: null,
      abn_years_age: null,
      repayment_frequency: null,
      preferred_financier: null,
      gst_months_age: null,
      gst_years_age: null,
      credit_score: null,
    }),
    []
  );

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    getValues,
    reset: resetForm,
  } = useForm({
    defaultValues: {
      ...defaultInputFormValues,
    },
  });

  const optionsAssetState = [
    { value: "none", label: "None" },
    { value: "new", label: "New" },
    { value: "used", label: "Used" },
    { value: "demo", label: "Demo" },
  ];
  const optionsGSTSelect = [
    { value: true, label: "Y", name: "Including GST" },
    { value: false, label: "N", name: "Excluding GST" },
  ];
  const optionsGSTWithPercentageSelect = [
    ...optionsGSTSelect,
    { value: "percentage", label: "%", name: "Percentage %" },
  ];

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (abn.length > 0) {
        setAbnLoading(true);
        setTimeout(() => {
          setAbnLoading(false);
          setAbnSuccess(true);
          abnSuccessChange();
        }, 3000);
      }
    }
  };

  const isNotDisabled = () => {
    const data = getValues();
    if (recordsFound) {
      return (
        changes > 0 &&
        data.cost &&
        data.term &&
        parseInt(watch("deposit") || "0") < parseInt(watch("cost") || "0")
      );
    }
    return (
      data.cost &&
      data.term &&
      parseInt(watch("deposit") || "0") < parseInt(watch("cost") || "0")
    );
  };

  const abnSuccessChange = () => {
    setTimeout(() => {
      setAbnSuccess(false);
    }, 2000);
  };

  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    setHovered(topPosition);
  }, [topPosition]);

  useEffect(() => {
    if (clearStates) {
      resetForm(defaultInputFormValues);
      // @ts-ignore
      setFormData();
      setRepaymentResultsData({});
      setScrolled(false);
      setClearStates(false);
      setReset(true);
    }
  }, [
    clearStates,
    resetForm,
    setFormData,
    setRepaymentResultsData,
    setScrolled,
    setClearStates,
    defaultInputFormValues,
  ]);

  useEffect(() => {
    resetForm(formData);
  }, [cancelChanges, dispatch, resetForm, formData]);

  const compareArrays = useCallback(
    (arr1: any, arr2: any) => {
      if (!recordsFound) return false;

      if (!arr1 && !arr2) {
        return false;
      }
      if (arr1 && arr1.length > 0 && !arr2) return true;
      if (!arr1 && arr2 && arr2.length > 0) return true;

      // Use a Set to store the combined label-value pairs of arr2 for quick lookup
      const arr2Set = new Set(
        arr2.map((item: any) => `${item.label}-${item.value}`)
      );

      for (let i = 0; i < arr1.length; i++) {
        const key = `${arr1[i].label}-${arr1[i].value}`;
        if (!arr2Set.has(key)) {
          return true;
        }
      }
      return false;
    },
    [recordsFound]
  );

  const getDiffCount = useCallback(
    (obj1: any, obj2: any) => {
      let count = 0;
      if (obj2 && obj1) {
        if (obj1.asset_owner !== obj2.asset_owner) {
          count++;
        }
        if (obj1.cost !== obj2.cost) {
          count++;
        }
        if (obj1.abn_months_age !== obj2.abn_months_age) {
          count++;
        }
        if (obj1.abn_years_age !== obj2.abn_years_age) {
          count++;
        }
        if (obj1.advance_arrears !== obj2.advance_arrears) {
          count++;
        }
        if (obj1.credit_score !== obj2.credit_score) {
          count++;
        }
        if (obj1.gst_months_age !== obj2.gst_months_age) {
          count++;
        }
        if (obj1.gst_years_age !== obj2.gst_years_age) {
          count++;
        }
        if (obj1.asset_months_age !== obj2.asset_months_age) {
          count++;
        }
        if (obj1.asset_state !== obj2.asset_state) {
          count++;
        }
        if (obj1.asset_years_age !== obj2.asset_years_age) {
          count++;
        }
        if (obj1.balloon !== obj2.balloon) {
          count++;
        }
        if (obj1.balloon_gst !== obj2.balloon_gst) {
          count++;
        }
        if (obj1.balloon_percentage !== obj2.balloon_percentage) {
          count++;
        }
        if (obj1.brokerage !== obj2.brokerage) {
          count++;
        }
        if (obj1.brokerage_gst !== obj2.brokerage_gst) {
          count++;
        }
        if (obj1.brokerage_percentage !== obj2.brokerage_percentage) {
          count++;
        }
        if (obj1.cost_gst !== obj2.cost_gst) {
          count++;
        }
        if (obj1.deposit !== obj2.deposit) {
          count++;
        }
        if (obj1.deposit_gst !== obj2.deposit_gst) {
          count++;
        }
        if (obj1.deposit_percentage !== obj2.deposit_percentage) {
          count++;
        }
        if (obj1.doc_fee_financed !== obj2.doc_fee_financed) {
          count++;
        }
        if (
          compareArrays(obj1?.preferred_financier, obj2?.preferred_financier)
        ) {
          count++;
        }
        if (obj1.private_sale !== obj2.private_sale) {
          count++;
        }
        if (obj1.repayment_frequency !== obj2.repayment_frequency) {
          count++;
        }
        if (obj1.sale_hire_back !== obj2.sale_hire_back) {
          count++;
        }
        if (obj1.term !== obj2.term) {
          count++;
        }
      }
      return count;
    },
    [compareArrays]
  );

  type FieldName = keyof typeof defaultInputFormValues;
  const setFormValue = useCallback(
    (e: ChangeEvent<HTMLInputElement>, fieldName: FieldName) => {
      if (e.target.value === "") {
        setValue(fieldName, null);
      } else {
        setValue(fieldName, e.target.value);
      }
    },
    [setValue]
  );

  useEffect(() => {
    const diffCount = getDiffCount(getValues(), formData);
    setChanges(diffCount);
  }, [getDiffCount, getValues, refreshState, setChanges, formData]);

  return (
    <div
      className={`relative container mx-auto z-20 ${
        scrolled ? `sticky top-[20px]` : ""
      } ${isCustomBigScreen ? "" : "max-w-[1205px]"}`}
      ref={filterRef}
      style={{
        width: `${scrolled && `${currentContainerWidth}px`}`,
      }}
    >
      {scrolled && !hovered ? (
        <HoveredFormData
          formData={formData}
          isMobile={isMobile}
          isCustomBigScreen={isCustomBigScreen}
          scrollTop={scrollTop}
          filterRef={filterRef}
          currentContainerWidth={currentContainerWidth}
          parentRef={parentRef}
        />
      ) : (
        <form onSubmit={handleSubmit(handleInputForm)}>
          <div className="bg-[#3e2d8d] rounded-xl p-10 shadow-2xl flex flex-col gap-6">
            <div className={`flex ${isMobile ? "flex-col" : "flex-row"} gap-8`}>
              <InputWithSelect
                showVertical={true}
                title="Asset Cost"
                input_name="cost"
                input_id="cost"
                select_name="cost_gst"
                options={optionsGSTSelect}
                register={register}
                watch={watch}
                control={control}
                defaultValue1={watch("cost")}
                defaultValue2={watch("cost_gst")}
                setValue={setValue}
              />
              <InputSelect
                showVertical={true}
                title="Term"
                name="term"
                id="term"
                options={optionsTermSelect}
                control={control}
                defaultValue={watch("term")}
                isMobile={isMobile}
                setValue={setValue}
                reset={reset}
                setReset={setReset}
                watch={watch}
              />
              <InputWithSelect
                showVertical={true}
                title="Deposit"
                input_name="deposit"
                input_id="deposit"
                select_name="deposit_gst"
                options={optionsGSTWithPercentageSelect}
                register={register}
                watch={watch}
                control={control}
                defaultValue1={watch("deposit")}
                defaultValue2={watch("deposit_gst")}
                setValue={setValue}
              />
              <InputWithSelect
                showVertical={true}
                title="Brokerage"
                input_name="brokerage"
                input_id="brokerage"
                select_name="brokerage_gst"
                options={optionsGSTWithPercentageSelect}
                register={register}
                watch={watch}
                control={control}
                defaultValue1={watch("brokerage")}
                defaultValue2={watch("brokerage_gst")}
                setValue={setValue}
              />
              <InputWithSelect
                showVertical={true}
                title="Balloon"
                input_name="balloon"
                input_id="balloon"
                select_name="balloon_gst"
                options={optionsGSTWithPercentageSelect}
                register={register}
                watch={watch}
                control={control}
                defaultValue1={watch("balloon")}
                defaultValue2={watch("balloon_gst")}
                setValue={setValue}
              />
            </div>
            <HorizontalBlueDivider />
            <div
              className={`flex ${isMobile ? "flex-col" : "flex-row"} gap-8 items-center`}
            >
              <div className="flex flex-row gap-2">
                <CustomDropdown
                  options={dropDownOptions}
                  title="Doc Fee Financed"
                  name="doc_fee_financed"
                  id="doc_fee_financed"
                  setValue={setValue}
                  currentState={doc_fee_financed}
                  setState={setDocFeeFinanced}
                  cancelChanges={cancelChanges}
                  formData={getValues()}
                  refreshState={refreshState}
                  setRefreshState={setRefreshState}
                />
                <CustomDropdown
                  options={dropDownOptions}
                  title="Private Sale"
                  name="private_sale"
                  id="private_sale"
                  setValue={setValue}
                  currentState={private_sale}
                  setState={setPrivateSale}
                  cancelChanges={cancelChanges}
                  formData={getValues()}
                  refreshState={refreshState}
                  setRefreshState={setRefreshState}
                />
                <CustomDropdown
                  options={dropDownOptions}
                  title="Sale Hire Back"
                  name="sale_hire_back"
                  id="sale_hire_back"
                  setValue={setValue}
                  currentState={sale_hire_back}
                  setState={setSaleHireBack}
                  cancelChanges={cancelChanges}
                  formData={getValues()}
                  refreshState={refreshState}
                  setRefreshState={setRefreshState}
                />
                <CustomDropdown
                  options={dropDownOptions}
                  title="Home Owner"
                  name="asset_owner"
                  id="asset_owner"
                  setValue={setValue}
                  currentState={asset_owner}
                  setState={setAssetOwner}
                  cancelChanges={cancelChanges}
                  formData={getValues()}
                  refreshState={refreshState}
                  setRefreshState={setRefreshState}
                />
              </div>
              <InputSelect
                showVertical={false}
                title="Asset State"
                name="asset_state"
                id="asset_state"
                options={optionsAssetState}
                control={control}
                defaultValue={null}
                isMobile={isMobile}
                setValue={setValue}
                reset={reset}
                setReset={setReset}
                fixedWidth={true}
                watch={watch}
              />
              <div className="flex flex-row items-center mx-2">
                <div className="text-[#b2abd1] text-base mx-2">Asset Age</div>
                <div className="bg-[#514298] flex flex-row ml-2 rounded text-white items-center pl-2 h-[3rem]">
                  <div className="ml-2 text-base">Y</div>
                  &nbsp;
                  <input
                    placeholder="..."
                    type="number"
                    className=" bg-inherit border-none text-base outline-0 w-10 p-2"
                    onChange={(e) => setFormValue(e, "asset_years_age")}
                    value={watch("asset_years_age") ?? ""}
                  />
                  <div className="text-base">M</div>
                  &nbsp;
                  <input
                    placeholder="..."
                    type="number"
                    className=" bg-inherit border-none outline-0 text-base rounded-r w-10 p-2"
                    onChange={(e) => setFormValue(e, "asset_months_age")}
                    value={watch("asset_months_age") ?? ""}
                  />
                </div>
              </div>
            </div>
            <HorizontalBlueDivider />
            <div className="flex w-full items-center justify-between items-center gap-8">
              {isEditing && (
                <div className={`flex flex-row rounded items-center`}>
                  <div
                    className={`bg-white flex flex-row border-2 border-[#fbca5d] rounded p-1 relative h-12 items-center ml-2`}
                  >
                    <div
                      className={`${
                        (abnLoading || abnSuccess) && "opacity-20"
                      } pl-2 text-base`}
                    >
                      ABN
                    </div>
                    <input
                      placeholder="Enter"
                      className={`ml-2 border-0 text-base p-0 w-36 font-bold text-black ${
                        (abnLoading || abnSuccess) && "opacity-20"
                      }`}
                      type="number"
                      value={abn}
                      onChange={(e) => setAbn(e.target.value)}
                      autoFocus={true}
                      onKeyDown={handleKeyDown}
                    />
                    {abnLoading && (
                      <div
                        role="status"
                        className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
                      >
                        <img
                          alt="..."
                          src={AbnLoader}
                          className="h-[70%] w-[70%] animate-spin"
                        />
                      </div>
                    )}
                    {!abnLoading && abnSuccess && (
                      <div
                        role="status"
                        className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2 bg-gray-200"
                      >
                        <AbnTick />
                      </div>
                    )}
                  </div>
                  <div
                    className=" cursor-pointer ml-2"
                    onClick={() => {
                      setIsEditing(false);
                      setAbn("");
                    }}
                  >
                    <CancelButton />
                  </div>
                </div>
              )}
              {!isEditing && (
                <>
                  <div className="flex flex-row items-center">
                    <div className="text-[#b2abd1] text-base">
                      GST Age
                    </div>
                    <div className="bg-[#514298] flex flex-row ml-2 rounded text-white items-center pl-2 h-[3rem]">
                      <div className="ml-2 text-base">Y</div>
                      &nbsp;
                      <input
                        placeholder="..."
                        type="number"
                        className=" bg-inherit border-none text-base outline-0 w-10 p-2"
                        onChange={(e) => setFormValue(e, "gst_years_age")}
                        value={watch("gst_years_age") ?? ""}
                      />
                      <div className="text-base">M</div>
                      &nbsp;
                      <input
                        placeholder="..."
                        type="number"
                        className=" bg-inherit border-none outline-0 text-base rounded-r w-10 p-2"
                        onChange={(e) => setFormValue(e, "gst_months_age")}
                        value={watch("gst_months_age") ?? ""}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row items-center">
                    <div className="text-[#b2abd1] text-base px-2">ABN Age</div>
                    <div className="px-2" onClick={() => setIsEditing(true)}>
                      <SearchIcon />
                    </div>
                    <div className="px-2 text-xl text-white">.</div>
                    <div className="bg-[#514298] flex flex-row ml-2 text-white rounded items-center h-12 pl-2">
                      &nbsp; &nbsp;
                      <div className="text-base">Y</div>
                      <input
                        placeholder="..."
                        type="number"
                        className=" bg-inherit text-base border-none outline-0 w-10 p-2"
                        onChange={(e) => setFormValue(e, "abn_years_age")}
                        value={watch("abn_years_age") ?? ""}
                      />
                      <div className="text-base">M</div>
                      <input
                        placeholder="..."
                        type="number"
                        className=" bg-inherit border-none text-base outline-0 w-10 p-2"
                        onChange={(e) => setFormValue(e, "abn_months_age")}
                        value={watch("abn_months_age") ?? ""}
                      />
                      &nbsp;
                    </div>
                  </div>
                </>
              )}
              <div className="flex flex-row items-center">
                <div className="text-[#b2abd1] text-base mx-2 items-center flex">
                  Credit Score
                </div>
                <input
                  name="credit_score"
                  type="number"
                  value={watch("credit_score") || ""}
                  placeholder="Enter"
                  onChange={(e) =>
                    //@ts-ignore
                    setValue("credit_score", parseInt(e.target.value))
                  }
                  className="bg-[#514298] placeholder:text-white placeholder:font-normal placeholder:text-opacity-60 border-[#514298] rounded flex-1 font-bold text-white focus:bg-[white] focus:border-[#ffcc00] focus:border-2 focus:text-black focus:outline-none"
                />
              </div>
              <div className="flex flex-row justify-center items-center">
                <div className="mx-2 text-[#b2abd1] text-base">
                  Preferred <br />
                  Financier
                </div>
                <CustomMultiselectWithChips
                  options={options}
                  onSelect={toggleRefresh}
                  setValue={setValue}
                  name="preferred_financier"
                  cancelChanges={cancelChanges}
                  formData={formData}
                  clearData={clearStates}
                />
              </div>
            </div>
            <HorizontalBlueDivider />
            <div
              className={`flex ${isMobile ? "flex-col" : "flex-row"} gap-8 items-center`}
            >
              <AdvanceArrears
                watch={watch}
                reset={reset}
                setReset={setReset}
                setValue={setValue}
              />
              <InputSelect
                showVertical={false}
                title="Repayment Frequency"
                name="repayment_frequency"
                id="repayment_frequency"
                options={optionsRepaymentFrequency}
                control={control}
                defaultValue={null}
                isMobile={isMobile}
                setValue={setValue}
                reset={reset}
                setReset={setReset}
                watch={watch}
              />
              {!isNotDisabled() && !directoryState.isLoading ? (
                <DisabledSubmit className="mr-8" />
              ) : (
                <button
                  type="submit"
                  data-tooltip-id="submit-button"
                  className="mr-8"
                >
                  {directoryState.isLoading ? (
                    <SubmitLoader />
                  ) : (
                    <SubmitButton />
                  )}
                </button>
              )}
              {changes !== 0 && (
                <Tooltip
                  id="submit-button"
                  place="right"
                  style={{ backgroundColor: "black" }}
                  isOpen={true}
                  defaultIsOpen={true}
                >
                <div className=" w-28 my-2">
                  You inputted some data without saving it.
                  <br />
                  <br /> <span className="font-bold">Reload </span>the
                  updated results (button on the left) or{" "}
                  <span className="font-bold">cancel </span> changes with
                  the link at the top or{" "}
                  <span className="font-bold">keep editing </span>.
                </div>
                </Tooltip>
              )}
            </div>
            {watch("deposit") &&
              watch("cost") &&
              parseInt(watch("deposit") || "0") >=
                parseInt(watch("cost") || "0") && (
                <div className="flex flex-row mx-3 pb-1 justify-end text-[#f74343]">
                  To continue fix the fields marked with red
                </div>
              )}
            {scrolled && (
              <div className="flex flex-row mx-3 pb-3 justify-end">
                {parseInt(changes) !== 0 && (
                  <div
                    className="text-white font-bold cursor-pointer mx-3"
                    onClick={() => setCancelChanges(!cancelChanges)}
                  >
                    Cancel Changes
                  </div>
                )}
                <div
                  className="text-yellow font-bold cursor-pointer mx-3"
                  onClick={() => {
                    setClearStates(true);
                    setRecordsFound(0);
                    setIsFormChanged(false);
                  }}
                >
                  Clear all filters
                </div>
              </div>
            )}
          </div>
        </form>
      )}
    </div>
  );
};

export default FilterPage;

const SubmittedFormFieldContainer = ({children}: {children: React.ReactNode}) => {
  return <div className="flex flex-col text-white mx-10 my-2">{children}</div>
}

const Label = ({ children }: { children: React.ReactNode }) => {
  return <div className="opacity-[60%]">{children}</div>;
};

const HoveredFormData = ({
  isMobile,
  isCustomBigScreen,
  formData,
  scrollTop,
  filterRef,
  currentContainerWidth,
  parentRef,
}: {
  isMobile: boolean;
  isCustomBigScreen: boolean;
  formData: any;
  scrollTop: () => void;
  filterRef: React.RefObject<HTMLDivElement>;
  currentContainerWidth: number;
  parentRef: any;
}) => {
  return (
    <div
      className={`container z-20 sticky mx-auto top-[20px] ${
        isCustomBigScreen ? "" : "max-w-[1205px]"
      }`}
      ref={filterRef}
    >
      <div className="bg-[#3e2d8d] flex flex-row rounded-xl py-1 shadow-2xl">
        <div
          className={`flex flex-wrap ${
            isMobile ? "flex-col" : "flex-row"
          } w-full my-2 text-base`}
        >
          {formData["cost"] && (
            <SubmittedFormFieldContainer>
              <Label>Asset Cost</Label>
              <div className="flex flex-row">
                ${formData["cost"]} &nbsp;
                <div className="opacity-[40%]">
                  {formData["cost_gst"] ? "GST" : "No GST"}
                </div>
              </div>
            </SubmittedFormFieldContainer>
          )}
          {formData["term"] && (
            <SubmittedFormFieldContainer>
              <Label>Term</Label>
              <div className="flex flex-row">
                {formData["term"]}&nbsp;
                <div className="opacity-[40%]">years</div>
              </div>
            </SubmittedFormFieldContainer>
          )}
          {
            <SubmittedFormFieldContainer>
              <Label>Deposit</Label>
              <div className="flex flex-row">
                {!formData["deposit_percentage"] && "$"}
                {formData["deposit"] || 0}&nbsp;
                <div className="opacity-[40%]">
                  {formData["deposit_gst"]
                    ? "GST"
                    : formData["deposit_percentage"]
                    ? "%"
                    : "No GST"}
                </div>
              </div>
            </SubmittedFormFieldContainer>
          }
          {
            <SubmittedFormFieldContainer>
              <Label>Brokerage</Label>
              <div className="flex flex-row">
                {!formData["brokerage_percentage"] && "$"}
                {formData["brokerage"] || 0}
                {formData["brokerage_gst"] ||
                  (!formData["brokerage_percentage"] && <> &nbsp;</>)}
                <div className="opacity-[40%]">
                  {formData["brokerage_gst"]
                    ? "GST"
                    : formData["brokerage_percentage"]
                    ? "%"
                    : "no GST"}
                </div>
              </div>
            </SubmittedFormFieldContainer>
          }
          {
            <SubmittedFormFieldContainer>
              <Label>Balloon</Label>
              <div className="flex flex-row">
                {!formData["brokerage_percentage"] && "$"}
                {formData["balloon"] || 0}
                {formData["brokerage_gst"] ||
                  (!formData["brokerage_percentage"] && <>&nbsp;</>)}
                <div className="opacity-[40%]">
                  {formData["brokerage_gst"]
                    ? "GST"
                    : formData["brokerage_percentage"]
                    ? "%"
                    : "no GST"}
                </div>
              </div>
            </SubmittedFormFieldContainer>
          }
          {formData["doc_fee_financed"] && (
            <SubmittedFormFieldContainer>
              <Label>Doc fee financed</Label>
              <div className="flex flex-row">Yes</div>
            </SubmittedFormFieldContainer>
          )}
          {
            <SubmittedFormFieldContainer>
              <Label>Private sale</Label>
              <div className="flex flex-row">
                {formData["private_sale"] ? "Yes" : "No"}
              </div>
            </SubmittedFormFieldContainer>
          }
          {formData["sale_hire_back"] && (
            <SubmittedFormFieldContainer>
              <Label>Sale hire back</Label>
              <div className="flex flex-row">Yes</div>
            </SubmittedFormFieldContainer>
          )}
          {formData["advance_arrears"] && (
            <SubmittedFormFieldContainer>
              <Label>
                {formData["advance_arrears"] === "advance"
                  ? "Advance"
                  : "Arrears"}
              </Label>
              <div className="flex flex-row">Only</div>
            </SubmittedFormFieldContainer>
          )}
          {formData["asset_owner"] && (
            <SubmittedFormFieldContainer>
              <Label>Asset owner</Label>
              <div className="flex flex-row">Yes</div>
            </SubmittedFormFieldContainer>
          )}
          {formData["asset_state"] && (
            <SubmittedFormFieldContainer>
              <Label>Asset State</Label>
              <div className="flex flex-row">{formData["asset_state"]}</div>
            </SubmittedFormFieldContainer>
          )}
          {parseInt(formData["abn_years_age"]) !== 0 && (
            <SubmittedFormFieldContainer>
              <Label>Abn Age</Label>
              <div className="flex flex-row">
                {formData["abn_years_age"]} Y {formData["abn_months_age"]} M
              </div>
            </SubmittedFormFieldContainer>
          )}
          {parseInt(formData["asset_years_age"]) !== 0 && (
            <SubmittedFormFieldContainer>
              <Label>Asset Age</Label>
              <div className="flex flex-row">
                {formData["asset_years_age"]} Y {formData["asset_months_age"]} M
              </div>
            </SubmittedFormFieldContainer>
          )}
          {formData["preferred_financier"] && (
            <SubmittedFormFieldContainer>
              <Label>Preferred Financier</Label>
              <div className="flex flex-row">
                {
                  // @ts-ignore
                  formData["preferred_financier"].map(
                    (data: any, index: number) => {
                      return (
                        <div key={index}>
                          {index !== 0 && ", "}
                          {data.label}
                        </div>
                      );
                    }
                  )
                }
              </div>
            </SubmittedFormFieldContainer>
          )}
          {formData["repayment_frequency"] && (
            <SubmittedFormFieldContainer>
              <Label>Repayment frequency</Label>
              <div className="flex flex-row">
                {formData["repayment_frequency"]}
              </div>
            </SubmittedFormFieldContainer>
          )}
        </div>
        <MinimizedIcon
          className="self-center mr-[2%] cursor-pointer"
          onClick={() => scrollTop()}
        />
      </div>
    </div>
  );
};
