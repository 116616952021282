import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  updateUserProfile,
  userDetails,
  userProfileStatus,
  userStatus,
} from "../../../redux/slices/userDataSlice";
import * as ls from "local-storage";

import { Logo } from "../../shared-components/logo";

const clsHeaderLinks =
  "flex flex-wrap justify-center mt-6 md:mt-0 w-full md:w-auto";

export const Header = () => {
  const userIsLoggedIn = useSelector(userStatus);
  const userData = useSelector(userDetails);
  const userHasProfile = useSelector(userProfileStatus);

  const dispatch = useDispatch();

  useEffect(() => {
    const userDataFromLS = JSON.parse(ls.get("userData")) || null;
    let hasProfile = false;
    if (userDataFromLS) {
      hasProfile =
        userDataFromLS.first_name && userDataFromLS.last_name ? true : false;
    }
    dispatch(
      updateUserProfile({ userData: userDataFromLS, hasProfile: hasProfile })
    );
  }, [userIsLoggedIn, dispatch]);

  let headerLinks;

  if (userIsLoggedIn && userHasProfile) {
    headerLinks = (
      <div className={clsHeaderLinks}>
        <div className="flex items-center text-center px-4 py-3">
          Welcome, {userData.first_name + " " + userData.last_name}
        </div>
        <HeaderLink
          text="Go to Calculator page"
          to="/calculator"
          isButton={true}
        />
      </div>
    );
  } else if (userIsLoggedIn && !userHasProfile) {
    headerLinks = (
      <div className={clsHeaderLinks}>
        <div className="flex items-center text-center px-4 py-3">
          Welcome, {userData.email}
        </div>
        <HeaderLink text="Complete signup" to="/profile" isButton={true} />
      </div>
    );
  } else {
    headerLinks = (
      <div className={clsHeaderLinks}>
        <HeaderLink text="Login" to="/login" isButton={true} />
      </div>
    );
  }

  return (
    <div>
      <div className="container px-4 py-8 mx-auto flex flex-wrap items-center justify-center md:justify-between">
        <Logo />
        {headerLinks}
      </div>
    </div>
  );
};

const HeaderLink: FC<{ to: string; text: string; isButton?: boolean }> = ({
  to,
  text,
  isButton = false,
}) => (
  <div className="flex items-center">
    <Link
      to={to}
      className={`flex items-center text-center px-6 py-3 rounded mt-0 ${
        isButton
          ? "text-white bg-primary ml-3 mr-3 sm:mr-0"
          : "text-dark bg-gray-200"
      }`}
    >
      {text}
    </Link>
  </div>
);
