import { FC } from "react";

export const HorizontalDivider: FC<{ marginY?: number; marginX?: number }> = ({
  marginY,
  marginX,
}) => (
  <div
    className={`border-b border-solid border-gray-200 ${
      marginY ? `my-${marginY}` : ""
    } ${marginX ? `mx-${marginX}` : ""}`}
  ></div>
);
export const HorizontalLightBlueDivider = () => (
  <div className="border-b border-solid border-[#6457a4] my-5"></div>
);
export const HorizontalBlueDivider: FC<{ marginY?: number; marginX?: number }> = (marginX, marginY) => (
  <div className={`border-b-[3px] border-solid border-[#483793] ${marginX ? `mx-${marginX}` : ""} ${
    marginY ? `my-${marginY}` : ""
  }`}></div>
);

export const VerticalDivider = () => (
  <div className="border-l-2 border-solid border-gray-300 my-5"></div>
);
