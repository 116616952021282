import React, { FC, PropsWithChildren, useEffect, useRef, useState, useCallback } from "react";
import DirectoryCustomDropdown from "./customDropdown/customDropdown";
import AddDirectoryModal from "../../financier/modal/addDirectoryModal";
import { useDispatch, useSelector } from "react-redux";
import {
  updateBracketData,
  updateProductsData,
  updateUpliftData,
  updateDirectoryComapnyId,
  clearUpliftData,
} from "../../../../redux/slices/directorySlice";
import { useMediaQuery } from "react-responsive";
import { directoryState as ds } from "../../../../redux/slices/directorySlice";
import { deleteCompany } from "../../../../services/company.service";
import { customToast } from "../../../shared-components/custom-toast";
import DeleteModal from "../../financier/modal/deleteModal";

interface Company {
  company_id: number;
  abn: string;
  type: string;
  name: string;
}

export const CompanyFilter:FC<PropsWithChildren<any>> = ({setCurrentWidth}) => {
  const [companyData, setCompanyData] = useState<Company>();
  const [reloadData, setReloadData] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const dispatch = useDispatch();
  const widthRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const directoryState = useSelector(ds);

  const isLoading = () => {
    return (directoryState.orgLoading)
  }

  const handleDelete = () => {
    deleteCompany(directoryState.selectedComapnyId)
      .then(successCallback)
      .catch(errorCallback);
  };
  const successCallback = useCallback((res: any) => {
    // customToast({message: "Company deleted success fully!", type: "success"})
    customToast({ message: res.data.msg, type: "success" });
    setShowDeleteModal(false);
    setCompanyData(undefined);
    dispatch(updateBracketData(null));
    dispatch(updateUpliftData(null));
    dispatch(updateDirectoryComapnyId(-1));
    dispatch(updateProductsData(null));
    dispatch(clearUpliftData());
    setReloadData(!reloadData);
  }, []);
  const errorCallback = useCallback((error: any) => {
    customToast({
      message: `${error.response.data.error || "Something went wrong"}`,
      type: "error",
    });
    setShowDeleteModal(false);
    setShowModal(true);
  }, []);

  useEffect(()=>{
    if(widthRef?.current){
      //@ts-ignore
      setCurrentWidth(widthRef.current.clientWidth);
    }
  }, [])
  useEffect(() => {
    setCompanyData(undefined);
    dispatch(updateBracketData(null));
    dispatch(updateUpliftData(null));
    dispatch(updateDirectoryComapnyId(-1));
    dispatch(updateProductsData(null));
    dispatch(clearUpliftData());
  }, [reloadData]);
  useEffect(()=>{
    setReloadData(!reloadData);
  }, [directoryState.toggleCompany])
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1170px)" });
  return (
    <>
      <div
        className={`flex ${
          !isSmallScreen ? "flex-row" : "flex-col"
        } ${isLoading() && "opacity-30"} items-center text-black`}
        ref={widthRef}
      >
        {isSmallScreen ? (
          <div className="flex flex-row w-1/2 items-center justify-between">
            <div className=" text-[32px] font-[700] pr-[2%] font-grotesk">Organization</div>
            <AddDirectoryModal
              type="add"
              setReloadData={setReloadData}
              reloadData={reloadData}
              companyData={companyData}
              setShowModal={setShowAddModal}
              setShowDeleteModal={setShowDeleteModal}
              showModal={showAddModal}
            />
          </div>
        ) : (
          <>
            <div className=" text-[32px] font-[700] pr-[2%] font-grotesk">Organization</div>
            <AddDirectoryModal
              type="add"
              setReloadData={setReloadData}
              reloadData={reloadData}
              companyData={companyData}
              setShowModal={setShowAddModal}
              setShowDeleteModal={setShowDeleteModal}
              showModal={showAddModal}
            />
          </>
        )}
        <DirectoryCustomDropdown
          setCompanyData={setCompanyData}
          componentName="CompanyFilter"
          reloadData={reloadData}
          setReloadData={setReloadData}
          refetch={refetch}
          setRefetch={setRefetch}
        />
        {companyData ? (
          <>
            <div
              className={`flex ${
                isSmallScreen ? "flex-row w-1/2 justify-between" : "flex-col"
              } px-[2%]`}
            >
              <div>Type</div>
              <div className="font-[700]">
                {companyData.type === "f"
                  ? "Financier"
                  : companyData.type === "u"
                  ? "User"
                  : "Broker"}
              </div>
            </div>
            <div
              className={`flex ${
                isSmallScreen ? "flex-row w-1/2 justify-between" : "flex-col"
              } px-[2%] flex-1`}
            >
              <div>ABN Number</div>
              <div className=" font-[700]">{companyData.abn}</div>
            </div>
            <AddDirectoryModal
              type="edit"
              companyData={companyData}
              setReloadData={setReloadData}
              reloadData={reloadData}
              setShowModal={setShowModal}
              setShowDeleteModal={setShowDeleteModal}
              showModal={showModal}
            />
          </>
        ) : (
          <></>
        )}
        <DeleteModal
          setShowDeleteModal={setShowDeleteModal}
          setEditModal={setShowModal}
          handleDelete={handleDelete}
          showDeleteModal={showDeleteModal}
          title="Organization"
          name={companyData?.name ?? ""}
        />
      </div>
    </>
  );
};
