import { useEffect, useState, useCallback } from "react";
import DirectoryCustomDropdown from "./customDropdown/customDropdown";
import { useDispatch, useSelector } from "react-redux";
import { directoryState as ds } from "../../../../redux/slices/directorySlice";
import {
  HorizontalDivider,
  HorizontalLightBlueDivider,
} from "../../form-components/divider";
import { ReactComponent as TickFilled } from "../../../../icons/tick_filled.svg";
import { ReactComponent as TickFilledWhite } from "../../../../icons/tick_filled_white.svg";
import { ReactComponent as CrossFilledWhite } from "../../../../icons/cross_filled_white.svg";
import { ReactComponent as CrossFilled } from "../../../../icons/cross_filled.svg";
import EditUpliftModal from "../../financier/modal/editUpliftModalComponent";
import DeleteModal from "../../financier/modal/deleteModal";
import ConfirmCancel from "../../financier/modal/confirmCancel";
import { useMediaQuery } from "react-responsive";
import capitalizeFirstLetter from "../../../../helpers/capitalizeFirstLetter";
import { deletUplift } from "../../../../services/uplifts.service";
import { customToast } from "../../../shared-components/custom-toast";
import { clearUpliftData, updateBracketData, updateUpliftData } from "../../../../redux/slices/directorySlice";

interface Company {
  id: number;
  abn: string;
  created_at: string;
  type: string;
  name: string;
  updated_at: string;
}

export const UpliftFilter = () => {
  const [companyData, setCompanyData] = useState<Company>();
  const directoryState = useSelector(ds);
  const [reloadData, setReloadData] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [showDeleteUpliftModal, setShowUpliftModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1170px)" });
  const [filtered_data, setFilteredData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setHovered(
      Object.keys(directoryState.upliftData).length === 0 ? true : false
    );
  }, [directoryState]);

  const handleDelete = () => {
    deletUplift(directoryState.upliftData.id)
      .then(successCallback)
      .catch(errorCallback);
  };
  const successCallback = useCallback((res: any) => {
    customToast({ message: res.data.message, type: "success" });
    setShowUpliftModal(false);
    dispatch(clearUpliftData());
    dispatch(updateBracketData(null));
    dispatch(updateUpliftData(null));
    setReloadData(!reloadData);
  }, []);
  const errorCallback = useCallback((error: any) => {
    customToast({
      message: `${error.response.data.detail || "Something went wrong"}`,
      type: "error",
    });
    setShowUpliftModal(false);
    setShowModal(true);
  }, []);

  const filterUpliftData = () => {
    const uplift_data_obj = directoryState.upliftData;
    const filtered_list = [];
    if (uplift_data_obj.min_asset_age || uplift_data_obj.max_asset_age) {
      filtered_list.push([
        "Asset Age",
        `${uplift_data_obj.min_asset_age || "0"}-${parseInt(
          uplift_data_obj.max_asset_age || "0"
        )}`,
        "M",
      ]);
    }
    if (uplift_data_obj.asset_state) {
      filtered_list.push([
        "Asset State",
        `${uplift_data_obj.asset_state || "None"}`,
      ]);
    }
    if (uplift_data_obj.min_abn_age || uplift_data_obj.max_abn_age) {
      filtered_list.push([
        "ABN Age",
        `${uplift_data_obj.min_abn_age || "0"}-${
          uplift_data_obj.max_abn_age || "0"
        }`,
        "M",
      ]);
    }
    if (uplift_data_obj.min_gst_age || uplift_data_obj.max_gst_age) {
      filtered_list.push([
        "GST Age",
        `${uplift_data_obj.min_gst_age || "0"}-${parseInt(
          uplift_data_obj.max_gst_age || "0"
        )} `,
        "M",
      ]);
    }
    if (uplift_data_obj.credit_score) {
      filtered_list.push([
        "Credit Score",
        `${uplift_data_obj.credit_score || "0"}`,
      ]);
    }
    if (
      uplift_data_obj.min_asset_age_plus_term ||
      uplift_data_obj.max_asset_age_plus_term
    ) {
      filtered_list.push([
        "Asset Age + Term",
        `${uplift_data_obj.min_asset_age_plus_term || "0"}-${
          uplift_data_obj.max_asset_age_plus_term || "0"
        }`,
        "M",
      ]);
    }
    if (uplift_data_obj.property_ownership_status) {
      filtered_list.push([
        "Property Ownership Status",
        uplift_data_obj.property_ownership_status,
      ]);
    }
    if (uplift_data_obj.sale_hire_back) {
      filtered_list.push(["Sale Hire Back", uplift_data_obj.sale_hire_back]);
    }
    if (uplift_data_obj.private_sale) {
      filtered_list.push(["Private Sale", uplift_data_obj.private_sale]);
    }
    if (uplift_data_obj.min_term || uplift_data_obj.max_term) {
      filtered_list.push([
        "Term",
        `${uplift_data_obj.min_term || "0"}-${uplift_data_obj.max_term || "0"}`,
        "M",
      ]);
    }
    if (uplift_data_obj.brokerage_loading_treatment) {
      filtered_list.push([
        "Brok. Loading Treatment",
        `${uplift_data_obj.brokerage_loading_treatment || "0"}`,
        "%",
      ]);
    }
    return filtered_list;
  };

  useEffect(() => {
    if (directoryState.upliftData) {
      const filteredData = filterUpliftData();
      // @ts-ignore
      setFilteredData(filteredData);
    }
  }, [directoryState.upliftData]);

  const isLast = (i: number, length: number) => {
    if (
      (i + 1 >= length || i + 2 >= length || i + 3 >= length) &&
      length >= 10
    ) {
      return true;
    }
    return false;
  };

  const renderUplift = () => {
    const upliftElements = [];
    for (let i = 0; i < filtered_data.length; i += 3) {
      upliftElements.push(
        <div
          key={i}
          className={`flex flex-col ${
            !isSmallScreen &&
            (isLast(i, filtered_data.length)
              ? "w-1/4"
              : "w-1/4 border-r pr-[3%] mr-[3%] text-base")
          } ${hovered ? "border-[#6457a4]" : "border-white"}`}
        >
          <div className="flex justify-between items-center flex-row my-2">
            <div>{filtered_data[i][0]}</div>
            <div className="flex flex-row">
              <div className="font-bold">
                {typeof filtered_data[i][1] === "boolean" ? (
                  filtered_data[i][1] ? (
                    <>{!hovered ? <TickFilled /> : <TickFilledWhite />}</>
                  ) : (
                    <>{!hovered ? <CrossFilled /> : <CrossFilledWhite />}</>
                  )
                ) : (
                  capitalizeFirstLetter(filtered_data[i][1])
                )}
              </div>
              {
                //@ts-ignore
                filtered_data[i].length > 2 && (
                  <div>&nbsp;{filtered_data[i][2]}</div>
                )
              }
            </div>
          </div>
          {i + 1 < filtered_data.length && (
            <div className="flex justify-between items-center flex-row my-2">
              <div>{filtered_data[i + 1][0]}</div>
              <div className="flex flex-row">
                <div className="font-bold">
                  {typeof filtered_data[i + 1][1] === "boolean" ? (
                    filtered_data[i + 1][1] ? (
                      <>{!hovered ? <TickFilled /> : <TickFilledWhite />}</>
                    ) : (
                      <>{!hovered ? <CrossFilled /> : <CrossFilledWhite />}</>
                    )
                  ) : (
                    capitalizeFirstLetter(filtered_data[i + 1][1])
                  )}
                </div>
                {
                  //@ts-ignore
                  filtered_data[i + 1].length > 2 && (
                    <div>&nbsp;{filtered_data[i + 1][2]}</div>
                  )
                }
              </div>
            </div>
          )}
          {i + 2 < filtered_data.length && (
            <div className="flex justify-between items-center flex-row my-2">
              <div>{filtered_data[i + 2][0]}</div>
              <div className="flex flex-row">
                <div className="font-bold">
                  {typeof filtered_data[i + 2][1] === "boolean" ? (
                    filtered_data[i + 2][1] ? (
                      <>{!hovered ? <TickFilled /> : <TickFilledWhite />}</>
                    ) : (
                      <>{!hovered ? <CrossFilled /> : <CrossFilledWhite />}</>
                    )
                  ) : (
                    capitalizeFirstLetter(filtered_data[i + 2][1])
                  )}
                </div>
                {
                  //@ts-ignore
                  filtered_data[i + 2].length > 2 && (
                    <div>&nbsp;{filtered_data[i + 2][2]}</div>
                  )
                }
              </div>
            </div>
          )}
        </div>
      );
    }
    return upliftElements;
  };

  const isLoading = () => {
    return (directoryState.orgLoading || directoryState.productLoading || directoryState.bracketLoading || directoryState.upliftLoading)
  }

  return (
    <div
      className={`${
        hovered ? "bg-[#3e2d8d] text-white" : "bg-[#fbca5d] text-black"
      } ${isLoading() && "opacity-30"} rounded-lg p-[2%] mt-[2%]`}
    >
      <div
        className={`flex ${
          isSmallScreen ? "flex-col" : "flex-row"
        } items-center ${
          Object.keys(directoryState.upliftData).length !== 0 &&
          renderUplift().length > 0 &&
          "mb-[1%]"
        }`}
      >
        {isSmallScreen ? (
          <div className="flex flex-row w-1/2 items-center justify-between">
            <div className=" text-[24px] font-[700] pr-[5%]">Uplift</div>
            <EditUpliftModal
              type={"add"}
              hovered={hovered}
              setShowDeleteModal={setShowUpliftModal}
              showModal={showAddModal}
              setShowModal={setShowAddModal}
              setReloadData={setReloadData}
              reloadData={reloadData}
              setShowCancelModal={setShowCancelModal}
              isLoading={isLoading()}
            />
          </div>
        ) : (
          <>
            <div className=" text-[24px] font-[700] pr-[5%]">Uplift</div>
            <EditUpliftModal
              type={"add"}
              hovered={hovered}
              setShowDeleteModal={setShowUpliftModal}
              showModal={showAddModal}
              setShowModal={setShowAddModal}
              setReloadData={setReloadData}
              reloadData={reloadData}
              setShowCancelModal={setShowCancelModal}
              isLoading={isLoading()}
            />
          </>
        )}
        <DeleteModal
          setShowDeleteModal={setShowUpliftModal}
          showDeleteModal={showDeleteUpliftModal}
          setEditModal={setShowModal}
          title="Uplift"
          name={directoryState.upliftData.uplift_name}
          handleDelete={handleDelete}
        />
        <ConfirmCancel
          setEditModal={setShowModal}
          setShowCancelModal={setShowCancelModal}
          showCancelModal={showCancelModal}
        />
        <DirectoryCustomDropdown
          setCompanyData={setCompanyData}
          componentName="UpliftFilter"
          reloadData={reloadData}
          hovered={hovered}
        />
        {Object.keys(directoryState.upliftData).length === 0 &&
        directoryState.upliftData.constructor === Object ? (
          <></>
        ) : (
          <>
            <div
              className={`flex ${
                isSmallScreen
                  ? "flex-row w-full justify-between"
                  : "flex-col px-[2%]"
              }`}
            >
              <div className={`${hovered && " opacity-70"}`}>Rate Change</div>
              <div className="font-[700]">
                {directoryState.upliftData.rate_change || "0"} %
              </div>
            </div>
            <div
              className={`flex ${
                isSmallScreen
                  ? "flex-row w-full justify-between"
                  : "flex-col px-[2%] flex-1"
              }`}
            >
              <div className={`${hovered && " opacity-70"}`}>Asset Cost</div>
              <div className=" font-[700]">
                <span className="font-normal opacity-80">$ </span>
                {directoryState.upliftData.min_asset_cost || 0} -{" "}
                <span className="font-normal opacity-80">$ </span>
                {directoryState.upliftData.max_asset_cost || 0}
              </div>
            </div>
            <EditUpliftModal
              type={"edit"}
              hovered={hovered}
              setShowDeleteModal={setShowUpliftModal}
              showModal={showModal}
              setShowModal={setShowModal}
              setReloadData={setReloadData}
              reloadData={reloadData}
              setShowCancelModal={setShowCancelModal}
              isLoading={isLoading()}
            />
          </>
        )}
      </div>
      {directoryState.upliftData &&
      Object.keys(directoryState.upliftData).length === 0 &&
      directoryState.upliftData.constructor === Object ? (
        <></>
      ) : !directoryState.upliftData ? (
        <></>
      ) : (
        <>
          {!isSmallScreen && renderUplift().length > 0 && (
            <>
              {" "}
              {!hovered ? (
                <HorizontalDivider marginY={5} />
              ) : (
                <HorizontalLightBlueDivider />
              )}
            </>
          )}
          {renderUplift().length > 0 && (
            <div
              className={`flex ${
                isSmallScreen ? "flex-col" : "flex-row"
              } w-full mt-[1%]`}
            >
              {renderUplift().map((data, index) => data)}
            </div>
          )}
        </>
      )}
    </div>
  );
};
