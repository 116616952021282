import React, { FC } from "react";

interface Props {
  showArrow: boolean;
  title: String;
  selected: boolean;
  toggle: () => void;
}

const HeaderButton: FC<Props> = ({ showArrow, title, selected, toggle }) => {
  return (
    <div
      className={`flex flex-row items-center justify-center cursor-default ${
        selected ? "bg-[#4a4a4a] rounded-2xl text-white" : "text-black"
      } px-4 py-1`}
      onClick={()=>toggle()}
    >
      <div className=" text-sm cursor-pointer">{title}</div>
      {showArrow && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-3 h-3 ml-1 cursor-pointer"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3"
          />
        </svg>
      )}
    </div>
  );
};

export default HeaderButton;
