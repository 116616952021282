import React, { FC, ReactNode, PropsWithChildren } from "react";

import { Banner } from "./banner";
import { Footer } from "./footer";
import { Header } from "./header";

type MarketingLayoutType = {
  title: string;
  subtitle: string | ReactNode;
  isTallBanner?: boolean;
};

export const MarketingLayout: FC<PropsWithChildren<MarketingLayoutType>> = ({
  title,
  subtitle,
  isTallBanner = false,
  children,
}) => {
  return (
    <div className="font-body tracking-wide text-gray-700">
      <Header />
      <Banner title={title} subtitle={subtitle} isTall={isTallBanner} />
      {children}
      <Footer />
    </div>
  );
};
