import { FC, ReactNode, useEffect, useRef, useState } from "react";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { Sidebar } from "./sidebar";
import { ProgressBar } from "../../../pages/shared-components/progress-bar";
import { useMediaQuery } from "react-responsive";
import FilterPage from "../calculator/filterPage";
import BackgroundPatternImage from "../../../icons/bg_image.svg";
import BackgroundPatternImageSmall from "../../../icons/small_bg.png";
import { ReactComponent as CrossDidYouKnow } from "../../../icons/did_you_know_cross.svg";

const styleMinHeight1 = { minHeight: "1px" };

type AppLayoutType = {
  title: string;
  isLoading?: boolean;
  children?: ReactNode;
  onScrollEnd?: () => void;
};
export const AppLayout: FC<AppLayoutType> = ({
  title,
  isLoading = false,
  children,
  onScrollEnd = () => {
    return; // TODO: Change When API integrated
  },
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 960px)" });
  const isDesktopScreen = useMediaQuery({ query: "(max-width: 1840px)" });
  const isCustomBigScreen = useMediaQuery({ query: "(min-width: 2000px)" });

  const scrollRef: any = useBottomScrollListener(onScrollEnd);
  const scrollRefParent: any = useBottomScrollListener(onScrollEnd);
  const filterRef = useRef(null);

  const resultParentRef = useRef(null);

  const [scrolled, setScrolled] = useState(false);

  const [recordsFound, setRecordsFound] = useState(0);
  const [clearStates, setClearStates] = useState(false);

  const [isFormChanged, setIsFormChanged] = useState(false);

  const [changes, setChanges] = useState();

  const [cancelChanges, setCancelChanges] = useState(false);

  const handleFormChange = () => {
    if (!isFormChanged) {
      setIsFormChanged(true);
    }
  };

  const scrollTop = () => {
    if (scrollRefParent.current) {
      scrollRefParent.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  const [topScrolled, setTopSCrolled] = useState(false);

  const [currentContainerWidth, setCurrentContainerWidth] = useState(0);

  useEffect(()=>{
    if(scrollRef.current){
      setCurrentContainerWidth(scrollRef.current.clientWidth);
    }
  }, [scrollRef])

  return (
    <div className="font-body text-gray-700 overflow-y-auto">
      <div className="absolute left-0 top-0 right-0 bottom-0 flex overflow-hidden">
        <div className=" w-8 h-full bg-[#f5f5f5]" />
        <div className="flex flex-col bg-[#f5f5f5]">
          <div
            className=" bg-[#f5f5f5] h-6"
            style={{
              backgroundImage: `url(${BackgroundPatternImageSmall})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
          <Sidebar />
          <div
            className=" bg-[#f5f5f5] h-6"
            style={{
              backgroundImage: `url(${BackgroundPatternImageSmall})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
        </div>
        <div
          className={`relative flex flex-1 flex-col w-full overflow-y-auto ${
            title === "Calculator" && !isDesktopScreen ? "px-[10%]" : !isMobile ? "px-[2%]": "px-0"
          } overflow-x-hidden
           bg-[#f5f5f5] min-h-screen`}
          style={{
            backgroundImage: `url(${BackgroundPatternImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            flex: `${isMobile ? "0 0 100vw" : "auto"}`,
          }}
          onScroll={() => {
            (scrollRefParent.current.scrollTop===0)? setTopSCrolled(true): topScrolled && setTopSCrolled(false);
            !scrolled &&
              recordsFound > 0 &&
              // @ts-ignore
              scrollRef.current.clientHeight + (filterRef.current.clientHeight / 3) >= window.innerHeight &&
              setScrolled(true);
          }}
          ref={scrollRefParent}
        >
          {!scrolled && title !== "Directory" && (
            <PageTitle
              title={title}
              isLoading={isLoading}
              recordsFound={recordsFound}
              clearAllStates={setClearStates}
              isFormChanged={isFormChanged}
              setIsFormChanged={setIsFormChanged}
              setRecordsFound={setRecordsFound}
              changes={changes}
              setCancelChanges={setCancelChanges}
              cancelChanges={cancelChanges}
            />
          )}
          {title === "Calculator" && (
            <FilterPage
              scrolled={scrolled}
              setRecordsFound={setRecordsFound}
              clearStates={clearStates}
              setClearStates={setClearStates}
              setScrolled={setScrolled}
              handleFormChange={handleFormChange}
              setIsFormChanged={setIsFormChanged}
              setChanges={setChanges}
              setCancelChanges={setCancelChanges}
              cancelChanges={cancelChanges}
              changes={changes}
              recordsFound={recordsFound}
              filterRef={filterRef}
              topPosition={topScrolled}
              scrollTop={scrollTop}
              parentRef={resultParentRef}
              currentContainerWidth={currentContainerWidth}
            />
          )}
          <div
            className="flex flex-auto relative"
            style={styleMinHeight1}
            ref={resultParentRef}
          >
            <div className={`container ${isCustomBigScreen? "": "max-w-[1205px]"} mx-auto`}>
              <div
                className="my-8 md:my-12"
                style={{
                  backgroundImage: `url(${BackgroundPatternImage})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                ref={scrollRef}
              >
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const PageTitle: FC<{
  title: string;
  isLoading: boolean;
  recordsFound: any;
  clearAllStates: any;
  isFormChanged: boolean;
  setIsFormChanged: React.Dispatch<React.SetStateAction<boolean>>;
  setRecordsFound: React.Dispatch<React.SetStateAction<number>>;
  changes: any;
  setCancelChanges: any;
  cancelChanges: any;
}> = ({
  title,
  isLoading,
  recordsFound,
  clearAllStates,
  isFormChanged,
  setIsFormChanged,
  setRecordsFound,
  setCancelChanges,
  cancelChanges,
  changes,
}) => {
  const [hideDoYouKnow, setHideDoYouKnow] = useState(false);
  const isCustomBigScreen = useMediaQuery({ query: "(min-width: 2000px)" });
  return (
    <div
      className={`flex ${isCustomBigScreen? "" : "max-w-[1205px]"} items-center flex-row justify-between relative bg-[#f5f5f5] border-gray-200 z-10 container mx-auto`}
      style={{
        backgroundImage: `url(${BackgroundPatternImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "72px",
      }}
    >
      <div className="flex flex-row items-center">
        <div className="text-3xl text-black font-bold font-grotesk">
          {isLoading ? "Loading..." : title}
        </div>
        {recordsFound !== 0 && (
          <div className="flex flex-row items-center">
            <div className="pl-4 text-[#7b7b7b] text-lg">.</div>
            <div className="pl-4 text-[#7b7b7b] text-lg">
              <span className="font-bold">{recordsFound}</span> results found
            </div>
            <div className="pl-4 text-[#7b7b7b] text-lg">.</div>
            {!hideDoYouKnow && (
              <div className="bg-white w-[50%] rounded px-2 flex flex-row mx-2 items-center">
                <div>
                  <span className="font-bold">Did you know?</span> You can
                  manually override the base rate by clicking on it in the
                  desired bracket.
                </div>
                <CrossDidYouKnow
                  className=" cursor-pointer h-11"
                  onClick={() => setHideDoYouKnow(true)}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <div className="flex flex-row items-center justify-center">
        {parseInt(changes) !== 0 && (
          <div
            className="text-bold mx-3 cursor-pointer text-[#3e2d8d] w-[8vw] text-right underline"
            onClick={() => setCancelChanges(!cancelChanges)}
          >
            <>
              Cancel <span className="font-bold">{changes}</span> Changes
            </>
          </div>
        )}
        {isFormChanged && (
          <div
            className=" border-2 border-[#3e2d8d] cursor-pointer text-center rounded-md p-2 w-[7vw] font-bold text-[#3e2d8d] text-base"
            onClick={() => {
              clearAllStates(true);
              setIsFormChanged(false);
              setRecordsFound(0);
            }}
          >
            Clear Filters
          </div>
        )}
      </div>
      <ProgressBar isLoading={isLoading} isBottom={true} />
    </div>
  );
};
