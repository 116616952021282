import React, { FC, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  directoryState as ds,
  updateAddUpliftData,
  updateUpliftData,
} from "../../../../../redux/slices/directorySlice";
import { useMediaQuery } from "react-responsive";

interface Props {
  type: any;
}

const ConditionsDropdown: FC<Props> = ({ type }) => {
  const [isOpen, setIsOpen] = useState(false);
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1170px)" });
  const options = [
    { label: "Asset State", value: "asset_state" },
    {
      label: "Brokerage Loading Treatment",
      value: "brokerage_loading_treatment",
    },
    { label: "Asset Age", value: "asset_age" },
    { label: "ABN Age", value: "abn_age" },
    { label: "Term", value: "term" },
    { label: "Asset Cost", value: "asset_cost" },
    { label: "Asset Age + Term", value: "asset_age_plus_term" },
    { label: "GST Age", value: "gst_age" },
    { label: "Credit Score", value: "credit_score" },
    { label: "Sale Hire Back", value: "sale_hire_back" },
    { label: "Private Sale", value: "private_sale" },
    { label: "Property Ownership Status", value: "property_ownership_status" },
  ];
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const dispatch = useDispatch();
  const directoryState = useSelector(ds);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLUListElement>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (selectedOptions.length === 0) {
      if (type === "add") {
        dispatch(updateAddUpliftData({ fields_name: [] }));
      } else {
        dispatch(updateUpliftData({ fields_name: [] }));
      }
    } else {
      const selectedOptionsList = selectedOptions.map(
        (index: number) => options[index].value
      );
      if (type === "add") {
        dispatch(updateAddUpliftData({ fields_name: selectedOptionsList }));
      } else {
        dispatch(updateUpliftData({ fields_name: selectedOptionsList }));
      }
    }
  }, [selectedOptions]);

  useEffect(() => {
    if (directoryState.upliftData && type !== "add") {
      const selectedEditData = directoryState.upliftData.fields_name.map(
        (fieldName: string) =>
          options.findIndex((option) => option.value === fieldName)
      );
      setSelectedOptions(selectedEditData);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        listRef.current &&
        !listRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative w-full" ref={dropdownRef}>
      <div
        className={`rounded-md cursor-pointer bg-[#f2f2f2] ${!isSmallScreen && "w-[26.5rem]"} appearance-none py-2 px-3 ${
          !isOpen
            ? `border-4 border-transparent text-black`
            : "border-[#463691] border-4"
        }`}
        onClick={toggleDropdown}
      >
        <div className="flex justify-between items-center">
          <span
            className={`${
              selectedOptions.length === 0
                ? "opacity-70"
                : "font-bold"
            } overflow-hidden whitespace-nowrap overflow-ellipsis max-w-[95%]`}
          >
            {selectedOptions.length === 0 && "Select"}
            {selectedOptions.map((data: any, index: any) => (
              <React.Fragment key={data}>
                {options[data].label}
                {index !== selectedOptions.length - 1 && ", "}
              </React.Fragment>
            ))}
          </span>
          <svg
            className={`w-4 h-4 transition-transform transform ${
              isOpen ? "-rotate-180" : "rotate-0"
            }`}
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M6.293 7.293a1 1 0 0 1 1.414 0L10 9.586l2.293-2.293a1 1 0 1 1 1.414 1.414l-3 3a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 0-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </div>
      {isOpen && (
        <ul
          className="absolute z-10 bg-[#3e2d8d] flex flex-row mt-1 rounded-md p-3 w-[94%] flex-wrap"
          ref={listRef}
        >
          {options.map((data: any, index) => {
            const isSelected = selectedOptions.includes(index);
            return (
              <React.Fragment key={index}>
                <div
                  className={`border-2 rounded ${
                    isSelected &&
                    "bg-[#6557a4] hover:bg-[#514298] hover:border-[#514298] hover:line-through decoration-[#fbca5d] decoration-2 hover:text-opacity-70"
                  } border-[#6557a4] w-fit p-2 my-1 cursor-pointer mx-2 text-white`}
                  onClick={() =>
                    setSelectedOptions((prev: any) => {
                      if (isSelected) {
                        return prev.filter((i: any) => i !== index);
                      } else {
                        return [...prev, index];
                      }
                    })
                  }
                >
                  {data.label}
                </div>
              </React.Fragment>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default ConditionsDropdown;
