import React, { FC } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";

import { Provider } from "react-redux";
import store from "./redux/store/store";

import { LoginPage } from "./pages/login/login.page";
import { SignupPage } from "./pages/login/signup.page";
import { ProfilePage } from "./pages/login/profile.page";
import { AddNewUserPage } from "./pages/login/add-new-user.page";
import { ForgotPasswordPage } from "./pages/login/forgot-password-page";

import { CalculatorPage } from "./pages/app/calculator/calculator.page";
// import { FinancierPage } from "./pages/app/financier/financier.page";

import { ProfileSettingsPage } from "./pages/app/settings/profile-settings.page";
import { SettingsPage } from "./pages/app/settings/settings.page";
import { SecuritySettingsPage } from "./pages/app/settings/security-settings.page";

import { HomePage } from "./pages/marketing/home.page";
import { NotFoundPage } from "./pages/marketing/not-found.page";
import { PrivacyPage } from "./pages/marketing/privacy.page";
import { TermsPage } from "./pages/marketing/terms.page";
import { EmailVerifyPage } from "./pages/login/email-verify.page";

import AuthenticatedRoute from "./authenticated.route";
import ResetPasswordPage from "./pages/login/reset-password.page";
import { DirectoryPage } from "./pages/app/directory/directory.page";

const RedirectToHome: FC<FallbackProps> = ({ resetErrorBoundary }) => {
  resetErrorBoundary();

  return <Navigate to="/" replace={true} />;
};

export const AppRouter = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ErrorBoundary FallbackComponent={RedirectToHome}>
          <ToastContainer />
          <FinputRoutes />
        </ErrorBoundary>
      </BrowserRouter>
    </Provider>
  );
};

const FinputRoutes = () => (
  <Routes>
    <Route path="/home" element={<HomePage />} />

    <Route path="/terms" element={<TermsPage />} />
    <Route path="/privacy" element={<PrivacyPage />} />
    <Route path="/login" element={<LoginPage />} />
    <Route path="/signup" element={<SignupPage />} />
    <Route path="/email-verify" element={<EmailVerifyPage />}/>
    <Route path ="/add-user" element = {<AddNewUserPage/>}/>
    <Route path="/email-verify" element={<EmailVerifyPage />} />
    <Route path="/forgot-password" element={<ForgotPasswordPage />} />
    <Route path="/reset-password/:uid/:token" element={<ResetPasswordPage />} />

    <Route element={<AuthenticatedRoute role={["admin", "user"]} />}>
      <Route path="/profile" element={<ProfilePage />} />

      <Route path="/settings" element={<SettingsPage />} />
      <Route path="/settings/profile" element={<ProfileSettingsPage />} />
      <Route path="/settings/security" element={<SecuritySettingsPage />} />
    </Route>

    <Route element={<AuthenticatedRoute role={["admin", "user"]} />}>
      <Route path="/calculator" element={<CalculatorPage />} />
    </Route>

    <Route element={<AuthenticatedRoute role={["admin"]} />}>
      {/* <Route path="/financier" element={<FinancierPage />} /> */}
      <Route path="/organization" element={<DirectoryPage />} />
    </Route>

    <Route path="/" element={<Navigate to="/home" replace={true} />} />
    <Route path="*" element={<NotFoundPage />} />
  </Routes>
);
